import styled from '@emotion/styled'
import { Section } from 'app/components/Common/Section'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  languageCode: string
  services: ServiceProps[]
}

export const IconsServices = memo(function IconsServices({
  languageCode,
  services,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      <Section label="services" languageCode={languageCode} />
      <Services dial={2} row wrap>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-top: 20.25rem;
  padding: 2.625rem 3.333vw 7.375rem;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 7.5rem;
    padding: 2.25rem 1.875rem 5.625rem;
  }
`

const Services = styled(FlexBox)`
  max-width: 46.875rem;
  margin: auto;

  @media (max-width: 1023px) {
    max-width: none;
  }
`
